<template>
  <div id="printMe" class="p-6 space-y-6">
    <div class="flex items-center justify-center col-span-4 space-x-2">
      <button @click="previousMonth">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 text-secondary"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 19l-7-7 7-7"
          />
        </svg>
      </button>
      <datepicker
        style="cursor: pointer !important; width: 9rem"
        class="
          border-none
          text-2xl text-center
          date-input
          bg-secondary
          text-white
          rounded-md
          py-0
        "
        :placeholder="'Month'"
        :inputFormat="calFormat"
        :minimumView="minimumView"
        :maximumView="'year'"
        :initialView="'year'"
        :locale="lan"
        v-model="actualTargetDate"
      ></datepicker>
      <button @click="nextMonth">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 text-secondary"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 5l7 7-7 7"
          />
        </svg>
      </button>
    </div>

    <ul class="nav flex justify-center print-display">
      <li class="nav-item ml-1.5 mr-1.5" v-for="item in types" :key="item">
        <button
          @click="changeCalFormat(item)"
          :class="{
            'bg-primary border-2 border-secondary': filter_type == item,
          }"
          class="
            mybtn
            border-primary border-2
            md:text-base
            hover:text-white hover:bg-primary hover:border-secondary
          "
        >
          <span class="capitalize">{{ $t(item) }}</span>
        </button>
      </li>
    </ul>
    <div>
      <div class="input w-full sm:w-1/3 print-display">
        <div class="block relative my-2 w-full">
          <span>{{ $t("Person") }}</span>
          <multiselect
            v-model="person"
            :options="persons"
            label="name"
            track-by="id"
            :multiple="true"
            selectedLabel
            deselectLabel
            selectLabel
            :placeholder="$t('filter person')"
          >
          </multiselect>
        </div>
      </div>
    </div>
    <div class="flex flex-col">
      <div class="-my-2 sm:-mx-6 lg:-mx-8">
        <div class="py-2 w-full sm:px-6 lg:px-8">
          <div
            class="
              shadow
              overflow-hidden
              border-b border-gray-200
              sm:rounded-lg
            "
          >
            <div class="min-w-full divide-y divide-gray-200">
              <div class="bg-gray-200 overflow-x-auto">
                <div
                  class="
                    text-xs
                    justify-between
                    text-primary
                    rounded
                    border-2
                    px-6
                    py-2
                    grid grid-cols-5
                    gap-2
                    w-640
                    sm:w-full
                    print-flex print-flex-row
                  "
                >
                  <div
                    scope="col"
                    class="
                      text-xs
                      font-bold
                      text-gray-500
                      tracking-wider
                      print-w-4 print-text-lg
                    "
                  >
                    {{ $t("Category") }}
                  </div>
                  <!-- <div
                    scope="col"
                    class="
                      text-right text-xs
                      font-bold
                      text-gray-500
                      tracking-wider
                    "
                  >
                    +/-
                  </div> -->
                  <div
                    scope="col"
                    class="
                      text-right text-xs
                      font-bold
                      text-gray-500
                      tracking-wider
                      print-w-2 print-text-right print-text-lg
                    "
                  >
                    {{ $t("is") }}
                  </div>
                  <div
                    scope="col"
                    class="
                      text-right text-xs
                      font-bold
                      text-gray-500
                      tracking-wider
                      print-w-2 print-text-right print-text-lg
                    "
                  >
                    {{ $t("Intended to") }}
                  </div>
                  <div
                    scope="col"
                    class="
                      text-right text-xs
                      font-bold
                      text-gray-500
                      tracking-wider
                      print-w-2 print-text-right print-text-lg
                    "
                  >
                    {{ $t("difference") }}
                  </div>
                  <div
                    scope="col"
                    class="
                      text-center text-xs
                      font-bold
                      text-gray-500
                      tracking-wider
                      print-w-2 print-text-right print-text-lg
                    "
                  >
                    {{ $t("Person") }}
                  </div>
                </div>
              </div>
              <div
                v-for="(cat, index) in Object.keys(actualTargetList)"
                :key="index"
              >
                <div class="flex">
                  <div
                    colspan="6"
                    class="
                      px-6
                      pt-4
                      whitespace-nowrap
                      text-md
                      font-bold
                      print-font-bold
                    "
                  >
                    {{ $t(cat) }}
                  </div>
                </div>
                <div class="overflow-x-auto">
                  <div
                    v-for="(item, index) in Object.keys(
                      actualTargetList[cat].items
                    )"
                    :key="index"
                    class="w-640 sm:w-full"
                  >
                    <div>
                      <div
                        class="
                          px-6
                          py-1
                          text-sm
                          font-medium
                          text-white
                          bg-primary
                          rounded-t-md
                          grid grid-cols-5
                          items-center
                          gap-2
                          print-flex print-flex-row
                        "
                      >
                        <div
                          class="
                            text-center text-xs
                            font-medium
                            text-white
                            tracking-wider
                            print-w-4
                          "
                        >
                          {{ $t(item) }}
                        </div>
                        <!-- <div
                          class="
                            text-center text-xs
                            font-medium
                            text-white
                            tracking-wider
                          "
                        >
                          {{
                            actualTargetList[cat].items[item].items[0]
                              .cat_in_out
                              ? "+"
                              : "-"
                          }}
                        </div> -->
                        <div
                          class="
                            text-right text-xs
                            font-medium
                            text-white
                            tracking-wider
                            print-w-2 print-text-right
                          "
                        >
                          {{
                            cat == "expenses" &&
                            actualTargetList[cat].items[item].totalMovement !==
                              0
                              ? "-"
                              : ""
                          }}
                          {{
                            parseFloat(
                              actualTargetList[cat].items[item].totalMovement
                            ).toLocaleString("de-DE", {
                              minimumFractionDigits: 2,
                            })
                          }}
                        </div>
                        <div
                          class="
                            text-right text-xs
                            font-medium
                            text-white
                            tracking-wider
                            print-w-2 print-text-right
                          "
                        >
                          {{
                            cat == "expenses" &&
                            actualTargetList[cat].items[item].totalPlanning !==
                              0
                              ? "-"
                              : ""
                          }}
                          {{
                            parseFloat(
                              actualTargetList[cat].items[item].totalPlanning
                            ).toLocaleString("de-DE", {
                              minimumFractionDigits: 2,
                            })
                          }}
                        </div>

                        <div
                          class="
                            text-right text-xs
                            font-medium
                            text-white
                            tracking-wider
                            print-w-2 print-text-right
                          "
                        >
                          {{
                            cat == "expenses" &&
                            parseFloat(
                              actualTargetList[cat].items[item].totalMovement -
                                actualTargetList[cat].items[item].totalPlanning
                            ) !== 0
                              ? "-"
                              : ""
                          }}
                          {{
                            Math.abs(
                              parseFloat(
                                actualTargetList[cat].items[item]
                                  .totalMovement -
                                  actualTargetList[cat].items[item]
                                    .totalPlanning
                              )
                            ).toLocaleString("de-DE", {
                              minimumFractionDigits: 2,
                            })
                          }}
                        </div>
                      </div>
                    </div>

                    <div
                      v-for="(itemDetails, index) in actualTargetList[cat]
                        .items[item].items"
                      :key="index"
                      class="
                        text-xs
                        justify-between
                        rounded
                        px-5
                        py-2
                        grid grid-cols-5
                        gap-2
                        bg-gray-100
                        mt-1
                        print-flex print-flex-row
                      "
                    >
                      <div
                        class="
                          text-sm
                          font-medium
                          text-gray-900 text-center
                          print-w-4
                        "
                      >
                        {{ $t(itemDetails.category) }}
                      </div>
                      <!-- <div
                        class="
                          px-6
                          whitespace-nowrap
                          text-sm
                          font-medium
                          text-gray-900 text-center
                        "
                      >
                        {{ itemDetails.cat_in_out ? "+" : "-" }}
                      </div> -->
                      <div
                        class="
                          text-sm
                          font-medium
                          text-gray-900 text-right
                          print-w-2 print-text-right
                        "
                        :class="{
                          'text-green-500': cat == 'incomes',
                          'text-gray-700': cat == 'save',
                          'text-red-500': cat == 'expenses',
                        }"
                      >
                        {{
                          itemDetails.cat_in_out
                            ? ""
                            : parseFloat(itemDetails.movement_amount) !== 0 &&
                              cat !== "expenses"
                            ? ""
                            : parseFloat(itemDetails.movement_amount) !== 0
                            ? "-"
                            : ""
                        }}
                        {{
                          parseFloat(
                            itemDetails.movement_amount
                          ).toLocaleString("de-DE", {
                            minimumFractionDigits: 2,
                          })
                        }}
                      </div>
                      <div
                        class="
                          text-sm
                          font-medium
                          text-gray-900 text-right
                          print-w-2 print-text-right
                        "
                        :class="{
                          'text-green-500': cat == 'incomes',
                          'text-gray-700': cat == 'save',
                          'text-red-500': cat == 'expenses',
                        }"
                      >
                        {{
                          itemDetails.cat_in_out
                            ? ""
                            : parseFloat(itemDetails.planning_amount) !== 0 &&
                              cat !== "expenses"
                            ? ""
                            : parseFloat(itemDetails.planning_amount) == 0
                            ? ""
                            : "-"
                        }}
                        {{
                          parseFloat(
                            itemDetails.planning_amount
                          ).toLocaleString("de-DE", {
                            minimumFractionDigits: 2,
                          })
                        }}
                      </div>
                      <!-- <div
                        class="whitespace-nowrap text-sm font-medium text-right"
                        :class="{
                          incomeColor:
                            parseFloat(itemDetails.movement_amount) <
                              parseFloat(itemDetails.planning_amount) &&
                            cat == 'incomes',
                          'text-gray-900':
                            parseFloat(itemDetails.movement_amount) >
                              parseFloat(itemDetails.planning_amount) &&
                            cat == 'incomes',
                          expensesColor:
                            parseFloat(itemDetails.movement_amount) >
                              parseFloat(itemDetails.planning_amount) &&
                            cat == 'expenses',
                          savesColor:
                            parseFloat(itemDetails.movement_amount) >
                              parseFloat(itemDetails.planning_amount) &&
                            cat == 'saves',
                          'text-red-500':
                            parseFloat(itemDetails.movement_amount) >
                              parseFloat(itemDetails.planning_amount) &&
                            cat == 'expenses',
                          'text-purple-500':
                            Math.sign(
                              parseFloat(itemDetails.movement_amount) +
                                parseFloat(itemDetails.planning_amount)
                            ) == false && cat == 'expenses',
                        }"
                      > -->
                      <div
                        class="
                          whitespace-nowrap
                          text-sm
                          font-medium
                          text-right
                          print-w-2 print-text-right
                        "
                        :class="{
                          'text-green-500': cat == 'incomes',
                          'text-gray-700': cat == 'save',
                          'text-red-500': cat == 'expenses',
                        }"
                      >
                        {{
                          cat == "expenses" &&
                          diffrenceCalculate(
                            itemDetails.movement_amount,
                            itemDetails.planning_amount,
                            cat
                          ) !== 0
                            ? "-"
                            : ""
                        }}
                        {{
                          Math.abs(
                            diffrenceCalculate(
                              itemDetails.movement_amount,
                              itemDetails.planning_amount,
                              cat
                            )
                          ).toLocaleString("de-DE", {
                            minimumFractionDigits: 2,
                          })
                        }}
                      </div>
                      <div
                        class="
                          px-6
                          text-sm
                          font-medium
                          text-gray-900 text-center
                          print-w-2 print-text-right
                        "
                      >
                        {{
                          itemDetails.person
                            ? itemDetails.person
                            : $t("together")
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="mt-1 w-640 sm:w-full">
                    <div
                      class="
                        bewegungen-inner
                        items-center
                        grid grid-cols-5
                        gap-2
                        bg-secondary
                        py-1
                        rounded-b-md
                        text-white
                        print-flex print-flex-row
                      "
                    >
                      <div class="gp-bew-cat col-span-1 font-normal print-w-4">
                        <span> {{ $t("Sum of") }} {{ $t(cat) }} </span>
                      </div>
                      <div
                        class="
                          gp-bew-betrag
                          text-right
                          font-normal
                          print-w-2 print-text-right
                        "
                      >
                        {{
                          cat == "expenses" &&
                          parseFloat(actualTargetList[cat].totalMovement) !== 0
                            ? "-"
                            : ""
                        }}
                        <span>
                          {{
                            parseFloat(
                              actualTargetList[cat].totalMovement
                            ).toLocaleString("de-DE", {
                              minimumFractionDigits: 2,
                            })
                          }}
                        </span>
                      </div>
                      <div
                        class="
                          gp-bew-betrag
                          text-right
                          font-normal
                          print-w-2 print-text-right
                        "
                      >
                        {{
                          cat == "expenses" &&
                          parseFloat(actualTargetList[cat].totalPlanning) !== 0
                            ? "-"
                            : ""
                        }}
                        <span>
                          {{
                            parseFloat(
                              actualTargetList[cat].totalPlanning
                            ).toLocaleString("de-DE", {
                              minimumFractionDigits: 2,
                            })
                          }}
                        </span>
                      </div>
                      <div
                        class="
                          gp-bew-betrag
                          text-right
                          font-normal
                          print-w-2 print-text-right
                        "
                      >
                        {{
                          cat == "expenses" &&
                          parseFloat(
                            actualTargetList[cat].totalPlanning -
                              actualTargetList[cat].totalMovement
                          ) !== 0
                            ? "-"
                            : ""
                        }}
                        <span>
                          {{
                            Math.abs(
                              parseFloat(
                                actualTargetList[cat].totalPlanning -
                                  actualTargetList[cat].totalMovement
                              )
                            ).toLocaleString("de-DE", {
                              minimumFractionDigits: 2,
                            })
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="mt-10 print-border-top"
      v-if="Object.keys(this.actualTargetList).length"
    >
      <span>{{ $t("Sum of incomes/expenses") }} </span>
      <div class="overflow-x-auto">
        <div
          class="
            bewegungen-inner
            items-center
            grid grid-cols-5
            gap-2
            w-640
            sm:w-full
            bg-gray-100
            print-flex print-flex-row
          "
        >
          <div
            class="gp-bew-cat col-span-1 font-bold print-w-4 print-font-bold"
          >
            <span>
              {{ $t("Total") }} - {{ actualTargetDate.getFullYear() }}
            </span>
          </div>
          <div
            class="
              gp-bew-betrag
              text-right
              font-normal
              print-w-2 print-text-right
            "
          >
            <span>
              {{ sumTotal(actualTargetList, "movement") }}
            </span>
          </div>
          <div
            class="
              gp-bew-betrag
              text-right
              font-normal
              print-w-2 print-text-right
            "
          >
            <span>
              {{ sumTotal(actualTargetList, "planning") }}
            </span>
          </div>
          <div
            class="
              gp-bew-betrag
              text-right
              font-normal
              print-w-2 print-text-right
            "
          >
            <span>
              {{
                Math.sign(
                  parseFloat(sumTotal(actualTargetList, "difference"))
                ) == 1
                  ? "- " + sumTotal(actualTargetList, "difference")
                  : sumTotal(actualTargetList, "difference").substring(1)
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <loading
      v-model:active="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
      :loader="'dots'"
      :color="'#963d97'"
    />
  </div>
</template>

<script>
const monthList = [
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec",
];
const types = ["monthly", "yearly"];

import Multiselect from "@suadelabs/vue3-multiselect";
import "@suadelabs/vue3-multiselect/dist/vue3-multiselect.css";
import Datepicker from "../../node_modules/vue3-datepicker";
import { de, it } from "date-fns/locale";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: { Multiselect, Datepicker, Loading },
  data() {
    return {
      monthList,
      person: null,
      filter_type: "monthly",
      types,
      touchBtn: false,
      persons: [{ id: "common", name: this.$t("common") }],
      actualTargetDate: new Date(),
      lan: this.$i18n.locale == "de" ? de : it,
      calFormat: "MM.yyyy",
      minimumView: "month",
      isLoading: false,
    };
  },
  watch: {
    person() {
      this.getActualTargetList();
    },
    filter_type() {
      this.getActualTargetList();
    },

    getPersons() {
      this.getPersons.map((i) => {
        this.persons.push(i);
      });
    },
    actualTargetDate: function () {
      this.getActualTargetList();
    },
  },
  computed: {
    actualTargetList() {
      return this.$store.state.statistic.actualTargetList;
    },
    getPersons() {
      return this.$store.state.User.persons.data;
    },
  },
  methods: {
    sum(array, key) {
      var arrayOfResult;
      if (key == "diffrence") {
        arrayOfResult =
          array.map(
            (m) => Number(m.movement_amount) - Number(m.planning_amount)
          ) || [];
      } else {
        arrayOfResult = array.map((m) => m[key]) || [];
      }
      var result = arrayOfResult.reduce(function (a, b) {
        return Number(a) + Number(b);
      }, 0);
      return result;
    },
    getActualTargetList() {
      this.isLoading = true;
      let person = null;
      if (this.person && this.person.length) {
        person =
          this.person && Object.values(this.person).length > 1
            ? Object.values(this.person.map((p) => p.id)).join(",")
            : this.person[0].id;
      }
      this.$store
        .dispatch("statistic/getActualTargetList", {
          from: `${this.actualTargetDate.getFullYear()}-${
            this.filter_type == "monthly"
              ? (this.actualTargetDate.getMonth() + 1)
                  .toString()
                  .padStart(2, "0")
              : "1"
          }-1`,
          to: `${this.actualTargetDate.getFullYear()}-${
            this.filter_type == "monthly"
              ? (this.actualTargetDate.getMonth() + 1)
                  .toString()
                  .padStart(2, "0")
              : "12"
          }-${this.endMonthDay()}`,
          person_id: person,
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    diffrenceCalculate(movement_amount, planning_amount) {
      const result = parseFloat(movement_amount) - parseFloat(planning_amount);
      return parseFloat(result);
    },
    sumTotal(data, type) {
      let totalIncomes = 0;
      let totalExpenses = 0;
      let result = 0;

      if (type == "movement") {
        totalIncomes = data["incomes"].totalMovement
          ? data["incomes"].totalMovement
          : 0;

        totalExpenses = data["expenses"].totalMovement
          ? data["expenses"].totalMovement
          : 0;

        result = totalIncomes - totalExpenses;
      }
      if (type == "planning") {
        totalIncomes = data["incomes"].totalPlanning
          ? data["incomes"].totalPlanning
          : 0;

        totalExpenses = data["expenses"].totalPlanning
          ? data["expenses"].totalPlanning
          : 0;
        result = totalIncomes - totalExpenses;
      }

      if (type == "difference") {
        let allPlanningIncomes = data["incomes"].totalPlanning
          ? data["incomes"].totalPlanning
          : 0;
        let allMovementIncomes = data["incomes"].totalMovement
          ? data["incomes"].totalMovement
          : 0;

        let allPlanningExpenses = data["expenses"].totalPlanning
          ? data["expenses"].totalPlanning
          : 0;
        let allMovementExpenses = data["expenses"].totalMovement
          ? data["expenses"].totalMovement
          : 0;

        result =
          allPlanningIncomes -
          allPlanningExpenses -
          (allMovementIncomes - allMovementExpenses);
      }

      return parseFloat(result).toLocaleString("de-DE", {
        minimumFractionDigits: 2,
      });
    },
    changeCalFormat(item) {
      this.filter_type = item;

      if (this.filter_type == "monthly") {
        this.calFormat = "MM.yyyy";
        this.minimumView = "month";
      } else {
        this.calFormat = "yyyy";
        this.minimumView = "year";
      }
    },
    endMonthDay() {
      const endDate = new Date(
        this.actualTargetDate.getFullYear(),
        this.actualTargetDate.getMonth() + 1,
        0
      );
      return endDate.getDate();
    },
    nextMonth() {
      if (this.filter_type == "monthly") {
        if (this.actualTargetDate.getMonth() == 11) {
          this.actualTargetDate = new Date(
            this.actualTargetDate.getFullYear() + 1,
            0,
            1
          );
        } else {
          this.actualTargetDate = new Date(
            this.actualTargetDate.getFullYear(),
            this.actualTargetDate.getMonth() + 1,
            1
          );
        }
      } else {
        this.actualTargetDate = new Date(
          this.actualTargetDate.getFullYear() + 1,
          11,
          31
        );
      }
    },
    previousMonth() {
      if (this.filter_type == "monthly") {
        if (this.actualTargetDate.getMonth() == 0) {
          this.actualTargetDate = new Date(
            this.actualTargetDate.getFullYear() - 1,
            11,
            1
          );
        } else {
          this.actualTargetDate = new Date(
            this.actualTargetDate.getFullYear(),
            this.actualTargetDate.getMonth() - 1,
            1
          );
        }
      } else {
        this.actualTargetDate = new Date(
          this.actualTargetDate.getFullYear() - 1,
          11,
          31
        );
      }
    },
  },
  mounted() {
    this.getActualTargetList();
    this.$store.dispatch("User/getPersons");
  },
};
</script>

<style></style>
